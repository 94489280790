
import { mapState } from "vuex";

export default {
  name: "LiensFooter",
  components: {
    Link: () => import("@/components/elements/Link.vue"),
  },
  computed: {
    isObfuscated() {
      return this.$route.path != "/";
    },
    ...mapState({
      socialMedias: (state) => {
        return state.radio.radioSocialMedia;
      },
    }),
  },
};
